import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import LandingPageContent from "../components/LandingPageContent/LandingPageContent";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityPage } from "../model/common";
import { LandingPageItem } from "../model/landing-page";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query LandingPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityLandingPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      _rawContent(resolveReferences: { maxDepth: 9 })
    }
  }
`;

const LandingPage = ({
  pageContext,
  data: { menus, page },
  location
}: LandingPageProps) => {
  const { title } = page;
  return (
    <PageLayout
      siteArea={SiteArea.UNKNOWN}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
      location={location}
      strings={pageContext.strings}
    >
      <LandingPageContent content={page._rawContent} title={page.title} />
    </PageLayout>
  );
};

export interface SanityLandingPage extends SanityPage {
  layout?: "default" | string;
  _rawContent: LandingPageItem[];
}

interface LandingPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityLandingPage;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}

export default LandingPage;
